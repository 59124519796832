<template>
  <div>
    <base-header type="gradient-success" class="pt-5 pb-6 pb-8 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <base-input-date
            inputClasses="input"
            data-no-dragscroll
            placeholder="TBI"
            v-model="eta"
          />
        </div>
      </div>
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <base-input-date
            inputClasses="input"
            data-no-dragscroll
            placeholder="TBI"
            v-model="etb"
          />
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col"></div>
      </div>
      <div class="mt-5 row">
        <div class="col"></div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "au",
    data() {
      return {
        eta: null,
        etb: "2024-02-05",
      };
    },
  };
</script>
<style></style>
