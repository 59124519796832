<template>
  <form @submit.prevent>
    <h6 class="heading-small text-muted mb-4"></h6>

    <div class="d-flex">
      <div class="col">
        <base-input
          alternative=""
          required
          label="Name"
          placeholder="name"
          input-classes="form-control-alternative"
          :errors="errors.name"
          v-model="model.name"
        />
      </div>
      <div class="col" v-if="type == 'navio' || type == 'navios'">
        <base-input
          alternative=""
          label="IMO"
          placeholder="imo"
          input-classes="form-control-alternative"
          :errors="errors.imo"
          v-model="model.imo"
        />
      </div>
      <div class="col" v-if="type == 'carga'">
        <base-input
          alternative=""
          required
          label="Synonym"
          placeholder="synonym"
          input-classes="form-control-alternative"
          :errors="errors.synonym"
          v-model="model.synonym"
        />
      </div>
      <div class="col" v-if="type == 'lineup_porto'">
        <base-input
          alternative=""
          required
          label="Country"
          placeholder="country"
          input-classes="form-control-alternative"
          :errors="errors.pais"
          v-model="model.pais"
        />
      </div>
    </div>
    <div class="d-flex" v-if="type == 'carga'">
      <div class="col">
        <div class="form-group has-label">
          <label class="form-control-label">To analyse</label>
          <br />
          <label class="custom-toggle mr-1 mt-2">
            <input
              type="checkbox"
              @change="model.ie_analise = model.ie_analise ? 0 : 1"
              :checked="model.ie_analise"
            />
            <span
              data-label-off="Off"
              data-label-on="On"
              class="custom-toggle-slider rounded-circle"
            ></span>
          </label>
          <div
            class="text-danger invalid-feedback"
            style="display: block"
            v-for="erro in errors['ie_analise']"
            v-if="errors['ie_analise']"
          >
            {{ erro }}
          </div>
        </div>
      </div>
      <div class="col">
        <multiple-select
          alternative=""
          label="Comm type"
          :errors="errors.tipo_id"
          placeholder="Comm type"
          :options="cargas"
          optionsValue="name"
          addonLeftIcon=" fa fa-plus"
          inputClasses="input"
          required
          input-classes="form-control-alternative"
          v-model="model.tipo_id"
        />
      </div>
    </div>
    <!-- <div class="d-flex" v-if="type == 'carga'">
     <div class="col">
      <div class="form-group has-label">
        <label class="form-control-label "> Import </label>
        <br>
        <label class="custom-toggle mr-1 mt-2">
          <input type="checkbox" @change="model.ie_import = model.ie_import?null:1" :checked="model.ie_import" >
          <span data-label-off="Off" data-label-on="On" class="custom-toggle-slider rounded-circle"></span>
        </label>
        <div class="text-danger invalid-feedback" style="display: block;"  v-for="erro in errors['ie_import']" v-if="errors['ie_import']">
          {{ erro }}
        </div>

      </div>

    </div>
    <div class="col">
      <div class="form-group has-label">
        <label class="form-control-label "> Export </label>
        <br>
        <label class="custom-toggle mr-1 mt-2">
          <input type="checkbox" @change="model.ie_export = model.ie_export?null:1" :checked="model.ie_export" >
          <span data-label-off="Off" data-label-on="On" class="custom-toggle-slider rounded-circle"></span>
        </label>
        <div class="text-danger invalid-feedback" style="display: block;"  v-for="erro in errors['ie_export']" v-if="errors['ie_export']">
          {{ erro }}
        </div>

      </div>

    </div>
  </div> -->

    <!--
 <button type="submit" class="btn base-button btn-dark float-left" @click="$router.push('/weekly_news/listar')">See listing</button> -->
    <!--    <button
      type="submit"
      class="btn base-button btn-primary float-right"
      :disabled="loading"
      @click="submit()"
    >
      Save
    </button> -->
    <div class="row pt-4">
      <div class="col-12 btn-final txt-right">
        <button
          type="submit"
          class="submit"
          value="Save"
          :disabled="loading"
          @click="submit()"
        >
          <i class="fa fa-spinner fa-spin" v-if="loading" /> Save
        </button>
      </div>
    </div>
  </form>
</template>
<script>
  import CargasService from "@/services/CargasTipos";
  export default {
    name: "generic_form",
    props: {
      type: {
        default: "agencia",
      },
      params: {
        type: Object,
        default: () => {},
      },
      id: {
        type: Number,
        default: null,
      },
      service: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        errors: {},
        loading: false,
        cargas: [],
        model: {
          name: "",
          ie_analise: undefined,
          is_naabsa_client: undefined,
          client_type: null,
          tipo_id: null,
        },
      };
    },
    created() {
      if (this.id) {
        this.model.id = this.id;
        this.find();
      } else if (this.type == "carga") {
        this.model.ie_analise = 1;
        CargasService.get().then((response) => {
          this.cargas = response.data;
        });
      } else if (this.type == "navio" || this.type == "navios") {
        this.model.is_naabsa_client = 0;
      }
    },
    methods: {
      find() {
        this.loading = true;
        this.errors = {};
        this.service
          .find(this.model.id, {})
          .then((response) => {
            this.model = response.data;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      },
      submit() {
        this.loading = true;
        this.errors = {};
        this.service
          .submit({ ...this.model, ...this.params })
          .then((response) => {
            this.model = response.data;
            this.loading = false;
            this.$notify({
              type: "success",
              message: "Saved successfully!",
              horizontalAlign: "center",
            });
            this.$emit("success", this.model);
          })
          .catch((err) => {
            this.loading = false;
            if (err.response && err.response.data && err.response.data.errors) {
              let errors = err.response.data.errors;
              this.errors = errors;
              this.$notify({
                type: "danger",
                message: "Please check the form",
                horizontalAlign: "center",
              });
            }
          });
      },
    },
  };
</script>
