<template>
  <div
    :class="[
      { 'input-group': hasIcon },
      // {'has-danger': errors.length},
      { 'form-group': label },
      { focused: focused },
      { 'has-label': label || $slots.label },
    ]"
  >
    <slot name="label">
      <label
        v-if="label"
        class="form-control-label w-100"
        :class="labelClasses"
      >
        {{ label }}
        <span v-if="required">*</span>
        <span class="float-right" v-if="locked"
          ><i
            title="Lock year?"
            class="fa"
            :class="lock ? 'fa-lock' : 'fa-lock-open'"
            @click="lock = lock ? false : true"
          ></i
        ></span>
      </label>
    </slot>

    <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend">
      <span class="input-group-text">
        <slot name="addonLeft">
          <i :class="addonLeftIcon"></i>
        </slot>
      </span>
    </div>
    <slot v-bind="slotData">
      <input
        v-mask="!locked || (locked && !lock) ? '##/##/####' : '##/##/' + year"
        v-model="model"
        @change.lazy="updateValue"
        @input="checkDate"
        @keydown.tab="handleTab"
        v-bind="$attrs"
        class="form-control"
        :class="[
          { 'is-valid': valid === true },
          { 'is-invalid': valid === false },
          inputClasses,
        ]"
        aria-describedby="addon-right addon-left"
      />
    </slot>
    <div v-if="addonRightIcon || $slots.addonRight" class="input-group-append">
      <span class="input-group-text">
        <slot name="addonRight">
          <i :class="addonRightIcon"></i>
        </slot>
      </span>
    </div>
    <slot name="infoBlock"></slot>
    <slot name="helpBlock">
      <!--   <div class="text-danger invalid-feedback" style="display: block;" :class="{'mt-2': hasIcon}" v-if="error">
      {{ error }}
    </div> -->
      <div
        class="text-danger invalid-feedback"
        style="display: block"
        :class="{ 'mt-2': hasIcon }"
        v-for="erro in errors"
        v-if="errors"
      >
        {{ erro }}
      </div>
      <div
        class="text-danger invalid-feedback"
        style="display: block"
        :class="{ 'mt-2': hasIcon }"
        v-for="erro in componentErrors"
        v-if="componentErrors"
      >
        {{ erro }}
      </div>
    </slot>
  </div>
</template>
<script>
  import Vue from "vue";
  import VueMask from "v-mask";

  Vue.use(VueMask);
  export default {
    inheritAttrs: false,
    name: "base-input-date",
    props: {
      required: {
        type: Boolean,
        description: "Whether input is required (adds an asterix *)",
      },
      // valid: {
      //   type: Boolean,
      //   description: "Whether is valid",
      //   default: undefined
      // },
      label: {
        type: String,
        description: "Input label (text before input)",
      },
      // error: {
      //   type: String,
      //   description: "Input error (below input)"
      // },
      errors: {
        type: Array,
        default: () => [],
        description: "Input error (below input)",
      },
      labelClasses: {
        type: String,
        description: "Input label css classes",
      },
      locked: {
        type: Boolean,
        default: true,
        description: "Input label css classes",
      },
      inputClasses: {
        type: String,
        description: "Input css classes",
      },
      value: {
        type: [String, Number],
        description: "Input value",
      },
      addonRightIcon: {
        type: String,
        description: "Addon right icon",
      },
      addonLeftIcon: {
        type: String,
        description: "Addont left icon",
      },
      handleTab: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        focused: false,
        model: "",
        lock: false,
        hasValue: false,
        year: "",
        month: "",
        atualYear: "",
        componentErrors: [],
      };
    },
    created() {
      if (this.locked) {
        this.lock = true;
      }
      this.month = new Date().getMonth() + 1;
      this.atualYear = new Date().getFullYear();

      if (this.value && this.value !== "") {
        this.hasValue = true;
        let date = this.$moment(this.value);
        this.model = date.format("DD/MM/YYYY");
        this.year = date.format("YYYY");
      } else {
        this.year = new Date().getFullYear();
        // this.year = '2025';
      }
    },
    computed: {
      valid() {
        return this.errors.length;
      },
      listeners() {
        return {
          ...this.$listeners,
          input: this.updateValue,
          focus: this.onFocus,
          blur: this.onBlur,
        };
      },
      slotData() {
        return {
          focused: this.focused,
          ...this.listeners,
        };
      },
      hasIcon() {
        const { addonRight, addonLeft } = this.$slots;
        return (
          addonRight !== undefined ||
          addonLeft !== undefined ||
          this.addonRightIcon !== undefined ||
          this.addonLeftIcon !== undefined
        );
      },
    },
    watch: {
      value: {
        immediate: true,
        handler(val, oldVal) {
          if (val) {
            let date = this.$moment(val);
            this.hasValue = true;
            this.model = date.format("DD/MM/YYYY");
            this.year = date.format("YYYY");
          } else {
            this.hasValue = false;
            this.model = "";
            this.year = this.year = new Date().getFullYear();
          }
          console.log("watch", val);
        },
      },
    },
    methods: {
      checkDate(evt) {
        let value = evt.target.value;
        if (value.length == 5 && this.month >= 11 && !this.hasValue) {
          let split = value.split("/");
          if (split[1] <= 2) {
            this.model = value + "/" + (this.atualYear + 1);
            let date = this.$moment(this.model, "DD/MM/YYYY", true);
            this.$emit("input", date.format("YYYY-MM-DD"));
          }
        }
      },
      updateValue(evt) {
        this.componentErrors = [];

        if (!this.model) {
          return this.$emit("input", this.model);
        }

        let date = this.$moment(this.model, "DD/MM/YYYY", true);
        console.log("change", this.value, this.year, date, this.model);
        if (!date.isValid()) {
          this.$emit("input", "");
          return this.componentErrors.push("Invalid date!");
        }

        let value = date.format("YYYY-MM-DD");
        this.$emit("input", value);
      },
      // onFocus(value) {
      //   this.focused = true;
      //   this.$emit("focus", value);
      // },
      // onBlur(value) {
      //   this.focused = false;
      //   this.$emit("blur", value);
      // }
    },
  };
</script>
<style scoped="">
  input {
    min-width: 75px;
    padding: 0.625rem 0.3rem;
  }
</style>
