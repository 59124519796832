<template>
  <div>
    <!--mobile-->
    <div
      class="pb-0 mb-0 row blc-descracao descracao_mobile"
      style="padding: 0 10px; margin: auto; display: block"
    >
      <div style="margin: auto; display: table">
        <div
          class=""
          v-if="!$root.isClient && userCanEdit()"
          style="display: flex; padding: 0"
        >
          <button
            class="btn-novo"
            :class="`${edit ? 'submit-vermelho' : 'submit-verde'}`"
            @click="toggleEdit()"
          >
            <i :class="`fa fa-${edit ? 'ban' : 'edit'}`"></i>
            {{ edit ? "Cancel edit" : "Edit lines" }}
          </button>
          <button
            style="align-self: center"
            class="submit btn-novo"
            v-if="!edit"
            @click="$emit('newLineup')"
          >
            New line +
          </button>

          <button class="submit btn-novo" v-if="edit" @click="addLine()">
            New line +
          </button>

          <div class="" @click="$emit('exportLineups')" v-if="!edit">
            <button style="position: relative" class="submit-verde btn-novo">
              Export
            </button>
          </div>
        </div>
        <div id="sub">
          <button
            v-if="edit"
            class="submit"
            @click="
              () => {
                list.push({
                  terminal_id: terminal.id,
                  porto_id: terminal.porto_id,
                  status: 'Expected',
                  is_observacao: 1,
                  observacao: null,
                  cargas_afretadores: [],
                  etb: null,
                  eta: null,
                  etc: null,
                  navio_id: null,
                  afretador_id: null,
                });
              }
            "
          >
            Add note
          </button>
          <button
            v-if="edit"
            class="float-right submit"
            @click="saveList()"
            :disabled="rowloading"
            style="margin-top: 12px"
          >
            <i
              class="fa fa-spinner fa-spin"
              v-if="rowloading"
              style="font-size: 20px"
            ></i>
            <i v-else>Save</i>
          </button>
        </div>
      </div>
    </div>
    <!--mobile-->

    <div class="pb-0 row blc-descracao">
      <div class="col-12">
        <div class="form-row">
          <div class="col-12">
            <div
              class="flex justify-between items-center w-100"
              style="
                background: #004581;
                border-radius: 5px;
                position: relative;
              "
            >
              <p
                style="
                  font-size: 28px;
                  font-weight: bold;
                  height: 50px;
                  background: #004581;
                  font-size: 18px;
                "
              >
                <b>{{ terminal.name }}</b>
              </p>
              <div
                class="blc-descracao-desktop"
                style="
                  margin-left: auto;
                  min-width: fit-content;
                  position: absolute;
                  right: 5px;
                  top: -6px;
                "
                v-if="!$root.isClient && userCanEdit()"
              >
                <button
                  class=""
                  :class="`${edit ? 'submit-vermelho' : 'submit-verde'}`"
                  @click="toggleEdit()"
                >
                  <i :class="`fa fa-${edit ? 'ban' : 'edit'}`"></i>
                  {{ edit ? "Cancel edit" : "Edit lines" }}
                </button>
                <button
                  class="submit-verde mx-1 bvtbn"
                  v-if="!edit"
                  @click="$emit('newLineup')"
                >
                  New line +
                </button>

                <button
                  class="submit-verde bvtbn mx-1"
                  v-if="edit"
                  @click="addLine()"
                >
                  New line +
                </button>
                <button
                  v-if="edit"
                  class="submit-verde bvtbn"
                  @click="
                    () => {
                      list.push({
                        terminal_id: terminal.id,
                        porto_id: terminal.porto_id,
                        status: 'Expected',
                        is_observacao: 1,
                        observacao: null,
                        cargas_afretadores: [],
                        etb: null,
                        eta: null,
                        etc: null,
                        navio_id: null,
                        afretador_id: null,
                      });
                    }
                  "
                >
                  Add note
                </button>
                <button
                  v-if="edit"
                  class="float-right submit-verde bvtbn mx-1"
                  @click="saveList()"
                  :disabled="rowloading"
                  style="margin-top: 12px"
                >
                  <i
                    class="fa fa-spinner fa-spin"
                    v-if="rowloading"
                    style="font-size: 20px"
                  ></i>
                  <i v-else>Save</i>
                </button>
                <button
                  class="submit-verde bvtbn"
                  @click="$emit('exportLineups')"
                  v-if="!edit"
                >
                  Export
                </button>
              </div>
            </div>
          </div>

          <div class="col-xl-2 col-lg-4 col-md-6 col-12">
            <p>Av. Waiting Time: {{ terminal.av_waiting_time || 0 }} Days</p>
          </div>

          <div class="col-xl-2 col-lg-4 col-md-6 col-12">
            <p>A.V W/D: {{ terminal.av_dw_bw || 0 }}</p>
          </div>

          <div class="col-xl-2 col-lg-4 col-md-6 col-12">
            <p>
              {{ carga.name == "FERTILIZER" ? "discharging" : "loading" }}
              rate: {{ terminal.rate || 0 }}
            </p>
          </div>

          <div class="col-xl-2 col-lg-4 col-md-6 col-12">
            <p>Max Draft: {{ terminal.max_draft || 0 }}m</p>
          </div>

          <div class="col-xl-2 col-lg-4 col-md-6 col-12">
            <p>Air Draft: {{ terminal.draft_berth || 0 }}m</p>
          </div>

          <div class="col-xl-2 col-lg-4 col-md-6 col-12">
            <p>Loa: {{ terminal.loa || 0 }}m</p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="blc-tabela zebrada scroll padding-table"
      style="margin: 0 10px"
      :class="{ 'padding-table': edit || inEdit }"
      v-dragscroll
    >
      <table class="zebrada" vue-dragscroll>
        <thead>
          <tr v-if="list.length">
            <th>Vessel</th>
            <th>Berth</th>
            <th>Status</th>
            <th style="max-width: 100px">QTTY</th>
            <th>Comm</th>
            <th>
              ETA
              <template class="float-right" v-if="edit"
                ><i
                  title="Lock year?"
                  class="fa"
                  :class="lock_eta ? 'fa-lock' : 'fa-lock-open'"
                  @click="lock_eta = lock_eta ? false : true"
                ></i
              ></template>
            </th>
            <th>
              ETB
              <template class="float-right" v-if="edit"
                ><i
                  title="Lock year?"
                  class="fa"
                  :class="lock_etb ? 'fa-lock' : 'fa-lock-open'"
                  @click="lock_etb = lock_etb ? false : true"
                ></i
              ></template>
            </th>
            <th>
              ETC
              <template class="float-right" v-if="edit"
                ><i
                  title="Lock year?"
                  class="fa"
                  :class="lock_etc ? 'fa-lock' : 'fa-lock-open'"
                  @click="lock_etc = lock_etc ? false : true"
                ></i
              ></template>
            </th>
            <th>Charter</th>
            <th v-if="!$root.isClient">Receiver|Shipper</th>
            <th v-if="carga.name == 'FERTILIZER'">POL</th>
            <th v-else>Disport</th>

            <th v-if="!$root.isClient">Agency</th>
            <th v-if="!$root.isClient">Naabsa client</th>
            <th></th>
          </tr>
        </thead>

        <tbody class="list" :class="{ inEdit: edit }">
          <template v-for="(row, index) in orderList">
            <tr
              :class="
                row.is_observacao && !row.isInEdit && !edit
                  ? 'observation-row'
                  : ''
              "
              :style="!$root.isClient ? getRowStyleByClientType(row) : ''"
            >
              <template v-if="row.is_observacao">
                <td colspan="5">
                  <template v-if="!row.isInEdit && !edit">
                    {{ row.observacao }}
                  </template>
                  <template v-else>
                    <base-input
                      :errors="errors[`list.${index}.observacao`]"
                      v-model="row.observacao"
                    />
                  </template>
                </td>
              </template>
              <template v-else>
                <td>
                  <template v-if="row.isInEdit == false && !edit">
                    {{ row.navio.name }}
                    <b v-if="row.call_number">
                      *{{
                        row.call_number + (row.call_number > 1 ? "ND" : "ST")
                      }}
                      CALL
                    </b>
                  </template>
                  <template v-else>
                    <multiple-select
                      optionsValue="name_with_imo"
                      :disabled="!navios.length"
                      :errors="errors[`list.${index}.navio_id`]"
                      addonLeftIcon="fa fa-plus"
                      inputClasses="input table-multiselect"
                      :options="navios"
                      selectLabel=""
                      selectedLabel=""
                      placeholder="select the vessel"
                      deselectLabel=""
                      @btn="
                        () => {
                          rowIndex = index;
                          openModalCreate('navio');
                        }
                      "
                      v-model="row.navio_id"
                    />
                  </template>
                </td>
                <td>
                  <template v-if="row.isInEdit == false && !edit">
                    {{ getBerthName(row) }}
                  </template>
                  <template v-else-if="terminal.bercos.length > 0">
                    <multiple-select
                      :disabled="!terminal.bercos.length"
                      :errors="errors[`list.${index}.berco_id`]"
                      addonLeftIcon="fa fa-plus"
                      inputClasses="input table-multiselect"
                      :options="terminal.bercos"
                      placeholder="select the berth"
                      v-model="row.berco_id"
                    />
                  </template>
                  <template v-else>
                    This terminal does not have berths
                  </template>
                </td>
                <td :class="!$root.isClient ? getClass(row) : ''">
                  <template v-if="row.isInEdit == false && !edit">
                    <span :style="getStatusStyle(row.status)">
                      {{
                        row.status.replace(
                          "hh:mm",
                          (row.pob
                            ? row.pob.substring(0, row.pob.length - 3)
                            : "") + " "
                        )
                      }}
                    </span>
                  </template>
                  <template v-else>
                    <base-select
                      :errors="errors[`list.${index}.status`]"
                      placeholder="Select the status"
                      options-key="name"
                      :options="Service.status()"
                      required
                      v-model="row.status"
                      @change="setStatus(row)"
                    />
                    <base-input
                      v-if="row.status == 'POB hh:mm lt'"
                      type="time"
                      v-model="row.pob"
                    />
                  </template>
                </td>
                <td>
                  <template v-if="row.isInEdit == false && !edit">
                    {{ formatQty(row.qty) }}
                  </template>
                  <template v-else>
                    <money
                      class="input"
                      v-model="row.qty"
                      v-bind="$root.qtd"
                    ></money>
                    <div
                      class="text-danger invalid-feedback"
                      style="display: block"
                      v-if="errors[`list.${index}.qty`]"
                      v-for="erro in errors[`list.${index}.qty`]"
                    >
                      {{ erro }}
                    </div>
                  </template>
                </td>
                <td>
                  <template v-if="!row.isInEdit && !edit">
                    <span
                      :style="`color: ${
                        row.carga ? row.carga.color : '#000000'
                      };`"
                    >
                      {{ row.carga && row.carga.name }}</span
                    >
                  </template>
                  <template v-else>
                    <multiple-select
                      optionsValue="name_with_synonym"
                      :disabled="!cargas.length"
                      :errors="errors[`list.${index}.carga_id`]"
                      addonLeftIcon="fa fa-plus"
                      required
                      :options="
                        carga_tipo_id.length > 0
                          ? cargas.filter((c) =>
                              carga_tipo_id.includes(c.tipo_id)
                            )
                          : cargas
                      "
                      @btn="
                        () => {
                          rowIndex = index;
                          openModalCreate('carga');
                        }
                      "
                      placeholder="select the comm"
                      inputClasses="input table-multiselect"
                      selectLabel=""
                      selectedLabel=""
                      :formGroup="false"
                      deselectLabel=""
                      @input="setCargaTipo(row)"
                      v-model="row.carga_id"
                    />
                  </template>
                </td>
              </template>
              <td
                v-if="!row.is_observacao"
                :class="!$root.isClient ? getClass(row) : ''"
              >
                <template v-if="row.isInEdit == false && !edit">
                  {{ row.eta ? formatDate(row.eta) : "TBI" }}
                </template>
                <template v-else>
                  <base-input-date
                    inputClasses="input"
                    data-no-dragscroll
                    :locked="lock_eta"
                    placeholder="TBI"
                    :errors="errors[`list.${index}.eta`]"
                    v-model="row.eta"
                  />
                </template>
              </td>
              <td v-else></td>
              <td>
                <template v-if="row.isInEdit == false && !edit">
                  {{ row.etb ? formatDate(row.etb) : "TBI" }}
                </template>
                <template v-else>
                  <base-input-date
                    :locked="lock_etb"
                    :errors="errors[`list.${index}.etb`]"
                    inputClasses="input"
                    data-no-dragscroll
                    max="9999-12-31"
                    placeholder="TBI"
                    v-model="row.etb"
                    :handleTab="
                      () => {
                        targetRow = row;
                        focusTargetEtc();
                      }
                    "
                  />
                </template>
              </td>
              <td>
                <template v-if="row.isInEdit == false && !edit">
                  {{ row.etc ? formatDate(row.etc) : "TBI" }}
                </template>
                <template v-else>
                  <span :ref="targetRow == row ? 'currentRowEtc' : ''">
                    <base-input-date
                      inputClasses="input"
                      :locked="lock_etc"
                      :errors="errors[`list.${index}.etc`]"
                      v-model="row.etc"
                      data-no-dragscroll
                      placeholder="TBI"
                      max="9999-12-31"
                    />
                  </span>
                </template>
              </td>
              <td>
                <template v-if="!row.isInEdit && !edit">
                  {{
                    row.is_observacao
                      ? "-"
                      : row.afretador
                      ? row.afretador.name
                      : ""
                  }}
                </template>
                <template v-else-if="!row.is_observacao">
                  <multiple-select
                    :errors="errors[`list.${index}.afretador_id`]"
                    :options="afretadores"
                    optionsValue="name"
                    :addonLeftIcon="
                      $root.isAdmin || $root.isBasicAdmin ? 'fa fa-plus' : null
                    "
                    required
                    inputClasses="input table-multiselect"
                    selectLabel=""
                    selectedLabel=""
                    :formGroup="false"
                    placeholder="select the charter"
                    deselectLabel=""
                    v-model="row.afretador_id"
                    @btn="
                      () => {
                        rowIndex = index;
                        if ($root.isAdmin || $root.isBasicAdmin) {
                          openModalCreate('afretador');
                        }
                      }
                    "
                  />
                </template>
              </td>
              <td v-if="!$root.isClient">
                <template v-if="!row.isInEdit && !edit">
                  {{
                    row.is_observacao
                      ? "-"
                      : row.recebedor
                      ? row.recebedor.name
                      : ""
                  }}
                </template>
                <template v-else-if="!row.is_observacao">
                  <multiple-select
                    :errors="errors[`list.${index}.recebedor_id`]"
                    :options="recebedores"
                    optionsValue="name"
                    addonLeftIcon="fa fa-plus"
                    required
                    inputClasses="input table-multiselect"
                    selectLabel=""
                    selectedLabel=""
                    :formGroup="false"
                    placeholder="select the receiver"
                    deselectLabel=""
                    v-model="row.recebedor_id"
                    @btn="
                      () => {
                        rowIndex = index;
                        openModalCreate('recebedor');
                      }
                    "
                  />
                </template>
              </td>
              <td>
                <template v-if="!row.isInEdit && !edit">
                  {{
                    row.is_observacao
                      ? "-"
                      : row.destination_porto
                      ? row.destination_port.name || row.destination_port.pais
                      : ""
                  }}
                </template>
                <template v-else-if="!row.is_observacao">
                  <multiple-select
                    :errors="errors[`list.${index}.destination_porto`]"
                    :options="lineupPortos"
                    optionsValue="port_name"
                    addonLeftIcon="fa fa-plus"
                    required
                    placeholder="select the disport"
                    @btn="
                      () => {
                        rowIndex = index;
                        openModalCreate('lineup_porto', 'destination_porto');
                      }
                    "
                    inputClasses="input table-multiselect"
                    selectLabel=""
                    selectedLabel=""
                    :formGroup="false"
                    deselectLabel=""
                    v-model="row.destination_porto"
                  />
                </template>
              </td>
              <td v-if="!$root.isClient">
                <template v-if="!row.isInEdit && !edit">
                  {{
                    row.is_observacao
                      ? "-"
                      : row.agencia
                      ? row.agencia.name
                      : ""
                  }}
                </template>
                <template v-else-if="!row.is_observacao">
                  <multiple-select
                    :disabled="!agencias.length"
                    :errors="errors[`list.${index}.agencia_id`]"
                    placeholder="Select the agency"
                    :options="agencias"
                    :addonLeftIcon="
                      $root.isAdmin || $root.isBasicAdmin ? 'fa fa-plus' : null
                    "
                    inputClasses="input table-multiselect"
                    selectLabel=""
                    selectedLabel=""
                    :formGroup="false"
                    deselectLabel=""
                    v-model="row.agencia_id"
                    ref="agencias"
                    @btn="
                      () => {
                        rowIndex = index;
                        if ($root.isAdmin || $root.isBasicAdmin) {
                          openModalCreate('agencia');
                        }
                      }
                    "
                  />
                </template>
              </td>
              <td v-if="!$root.isClient">
                <template v-if="!row.isInEdit && !edit">
                  {{
                    row.is_observacao
                      ? "-"
                      : row.client_type
                      ? row.client_type
                      : ""
                  }}
                </template>
                <template v-else-if="!row.is_observacao">
                  <base-select
                    :errors="errors[`list.${index}.client_type`]"
                    placeholder="Select the client type"
                    options-key="name"
                    :options="Service.clientTypes()"
                    v-model="row.client_type"
                  />
                </template>
              </td>
              <td class="text-right">
                <button
                  v-if="row.isInEdit && !rowloading && !edit"
                  @click="saveRow(row)"
                  class="p-1 mt-1 btn btn-primary redondo"
                >
                  save
                </button>
                <button
                  v-if="row.isInEdit && !rowloading && !edit"
                  class="p-1 btn btn-secondary redondo"
                  @click="cancelEditLine(row)"
                >
                  cancel
                </button>
                <div class="btn-group">
                  <button
                    v-if="edit"
                    class="btn btn-sm redondo btn-danger"
                    @click="deleteLine(row)"
                  >
                    <i class="fas fa-trash"></i>
                  </button>

                  <button
                    v-if="
                      (edit || row.isInEdit) &&
                      !row.is_observacao &&
                      row.cargas_afretadores &&
                      row.cargas_afretadores.length == 0
                    "
                    class="ml-1 btn btn-sm redondo btn-primary"
                    @click="addCargaAfretador(row)"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
                <i
                  class="fa fa-spinner fa-spin"
                  v-if="rowloading"
                  style="font-size: 20px"
                ></i>
                <base-dropdown
                  class="dropdown"
                  position="right"
                  v-if="
                    !$root.isClient &&
                    !edit &&
                    !inEdit &&
                    !row.isInEdit &&
                    userCanEdit()
                  "
                >
                  <a
                    slot="title"
                    class="btn btn-sm btn-icon-only"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style="height: 23px; margin-top: -30px"
                  >
                    <i class="fas fa-ellipsis-v"></i>
                  </a>

                  <template>
                    <a
                      class="dropdown-item"
                      @click="setEditLine(row)"
                      v-if="row.status != 'Sailed' && !edit"
                      ><i class="fas fa-edit"></i> edit in line
                    </a>
                    <a class="dropdown-item" @click="$emit('openModal', row)">
                      <i class="fas fa-edit"></i> edit in modal
                    </a>
                    <a
                      v-if="!row.is_observacao"
                      class="dropdown-item"
                      @click="
                        $emit('openModal', {
                          ...row,
                          id: null,
                        })
                      "
                    >
                      <i class="fas fa-plus"></i> add copy
                    </a>
                    <a
                      v-if="!row.is_observacao"
                      class="dropdown-item"
                      @click="
                        $emit('openModal', {
                          ...row,
                          call_in_id: row.id,
                        })
                      "
                    >
                      <i class="fas fa-plus"></i> add call
                    </a>
                    <a
                      class="dropdown-item"
                      @click="
                        $root.openExcluirModal(
                          row,
                          'Would you like to delete the lineup?',
                          Service
                        )
                      "
                      ><i class="fas fa-trash" style="min-width: 20px"></i>
                      remove</a
                    >
                  </template>
                </base-dropdown>
              </td>
            </tr>
            <tr
              v-for="(carga_afretador, i) in row.cargas_afretadores"
              :style="!$root.isClient ? getRowStyleByClientType(row) : ''"
            >
              <td colspan="3"></td>
              <td>
                <template v-if="!edit">
                  {{ formatQty(carga_afretador.qty) }}
                </template>
                <template v-else>
                  <money
                    class="input"
                    v-model="carga_afretador.qty"
                    v-bind="$root.qtd"
                  ></money>
                  <div
                    class="text-danger invalid-feedback"
                    style="display: block"
                    v-if="errors[`list.${index}.cargas_afretadores.${i}.qty`]"
                    v-for="erro in errors[
                      `list.${index}.cargas_afretadores.${i}.qty`
                    ]"
                  >
                    {{ erro }}
                  </div>
                </template>
              </td>
              <td>
                <template v-if="!edit">
                  <span
                    :style="`color: ${
                      getValue('cargas', carga_afretador.carga_id, 'color')
                        ? getValue('cargas', carga_afretador.carga_id, 'color')
                        : '#000000'
                    };`"
                  >
                    {{
                      carga_afretador.carga_id
                        ? getValue("cargas", carga_afretador.carga_id)
                        : ""
                    }}
                  </span>
                </template>
                <template v-else>
                  <multiple-select
                    :errors="
                      errors[`list.${index}.cargas_afretadores.${i}.carga_id`]
                    "
                    :options="cargas"
                    optionsValue="name"
                    addonLeftIcon="fa fa-plus"
                    required
                    inputClasses="input table-multiselect"
                    selectLabel=""
                    selectedLabel=""
                    :formGroup="false"
                    placeholder="select the comm"
                    deselectLabel=""
                    @btn="
                      () => {
                        rowIndex = index;
                        openModalCreate('afretador');
                      }
                    "
                    v-model="carga_afretador.carga_id"
                  />
                </template>
              </td>

              <td colspan="3"></td>
              <td>
                <template v-if="!edit">
                  {{
                    carga_afretador.afretador_id != row.afretador_id &&
                    carga_afretador.afretador_id
                      ? getValue("afretadores", carga_afretador.afretador_id)
                      : ""
                  }}
                </template>
                <template v-else>
                  <multiple-select
                    :errors="
                      errors[
                        `list.${index}.cargas_afretadores.${i}.afretador_id`
                      ]
                    "
                    :options="afretadores"
                    optionsValue="name"
                    :addonLeftIcon="
                      $root.isAdmin || $root.isBasicAdmin ? 'fa fa-plus' : null
                    "
                    required
                    inputClasses="input table-multiselect"
                    selectLabel=""
                    selectedLabel=""
                    :formGroup="false"
                    placeholder="select the charter"
                    deselectLabel=""
                    @btn="
                      () => {
                        rowIndex = index;
                        if ($root.isAdmin || $root.isBasicAdmin) {
                          openModalCreate('afretador');
                        }
                      }
                    "
                    v-model="carga_afretador.afretador_id"
                  />
                </template>
              </td>
              <td v-if="!$root.isClient">
                <template v-if="!edit">
                  {{
                    carga_afretador.recebedor_id != row.recebedor_id &&
                    carga_afretador.recebedor_id
                      ? getValue("recebedores", carga_afretador.recebedor_id)
                      : ""
                  }}
                </template>
                <template v-else>
                  <multiple-select
                    :errors="
                      errors[
                        `list.${index}.cargas_afretadores.${i}.recebedor_id`
                      ]
                    "
                    :options="recebedores"
                    optionsValue="name"
                    addonLeftIcon="fa fa-plus"
                    required
                    inputClasses="input table-multiselect"
                    selectLabel=""
                    selectedLabel=""
                    :formGroup="false"
                    placeholder="select"
                    deselectLabel=""
                    @btn="
                      () => {
                        rowIndex = index;
                        openModalCreate('recebedor');
                      }
                    "
                    v-model="carga_afretador.recebedor_id"
                  />
                </template>
              </td>
              <td :colspan="$root.isClient ? '1' : '3'"></td>
              <td style="text-align: right" coltemplate="2">
                <div class="btn-group">
                  <button
                    v-if="edit && !$root.isClient"
                    class="btn btn-sm btn-danger redondo"
                    @click="removeCargaAfretador(row, carga_afretador)"
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                  <button
                    class="ml-1 btn btn-sm btn-primary redondo"
                    v-if="
                      (edit || row.isInEdit) &&
                      row.cargas_afretadores.length == i + 1 &&
                      !$root.isClient
                    "
                    @click="addCargaAfretador(row)"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </td>
            </tr>
          </template>
          <tr v-if="!list.length && !loading">
            <td coltemplate="100" style="text-align: center">
              no vessel's to call
            </td>
          </tr>
          <tr v-if="loading">
            <td coltemplate="100" style="text-align: center">
              <i
                class="fa fa-spinner fa-spin"
                v-if="loading"
                style="font-size: 20px"
              ></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <modal
      :show="modal.create.show"
      @close="
        () => {
          modal.create.show = false;
        }
      "
    >
      <template v-slot:header> Add new </template>
      <template>
        <generic-form
          v-if="modal.create.show"
          :service="modal.create.service"
          :type="modal.create.type"
          :params="modal.create.params"
          @success="handleModalForm"
        />
      </template>
    </modal>
  </div>
</template>
<script>
  import Service from "@/services/Lineups";
  import LineupsPortosService from "@/services/LineupsPortos";
  import PortosService from "@/services/Portos";
  import NaviosService from "@/services/Navios";
  import TrabalhadoresService from "@/services/Trabalhadores";
  import AgenciasService from "@/services/Agencias";
  import RecebedoresService from "@/services/Recebedores";
  import AfretadoresService from "@/services/Afretadores";
  import CargasTiposService from "@/services/CargasTipos";
  import CargasService from "@/services/Cargas";
  import GenericForm from "./../Generic/Form.vue";
  import { Money } from "v-money";
  import moment from "moment";

  export default {
    props: {
      lineups: {
        default: () => [],
      },
      carga_tipo_id: {
        default: [],
      },
      carga: {
        default: () => {},
      },
      navios: {
        default: () => [],
      },
      cargas: {
        default: () => [],
      },
      recebedores: {
        default: () => [],
      },
      afretadores: {
        default: () => [],
      },
      lineupPortos: {
        default: () => [],
      },
      agencias: {
        default: () => [],
      },
      terminal: {
        default: () => ({}),
      },
      userPortosIds: {
        default: [],
      },
    },
    components: {
      Money,
      "generic-form": GenericForm,
    },
    computed: {
      inEdit() {
        return this.list.filter((lineups) => lineups.isInEdit == true).length;
      },
      orderList() {
        return _.orderBy(this.list, ["etb"], ["asc"]);
      },
    },
    data() {
      return {
        lock_eta: true,
        lock_etb: true,
        lock_etc: true,
        rowIndex: null,
        Service,
        loading: false,
        editRow: {},
        edit: false,
        rowloading: false,
        search: {
          terminal_id: null,
          carga_tipo_id: [],
        },
        errors: {},
        orderBy: {
          campo: "etb",
          ordem: "asc",
        },
        list: [],
        modal: {
          create: {
            show: false,
            service: {},
            type: null,
            params: {},
            model: null,
            index: null,
          },
          confirm: {
            show: false,
          },
        },
        targetRow: null,
        deletedLines: [],
      };
    },
    created() {
      if (this.lineups) {
        this.list = this.lineups;
      } else {
        this.listar();
      }
    },
    watch: {
      lineups: {
        deep: true,
        handler() {
          this.list = this.lineups;
        },
      },
      search: {
        deep: true,
        handler() {
          this.listar();
        },
      },
      orderBy: {
        deep: true,
        handler() {
          this.pagination.page = 1;
          this.listar();
        },
      },
    },
    methods: {
      addLine() {
        this.list.push({
          navio_id: null,
          status: null,
          qty: 0,
          carga_id: null,
          carga_tipo_id: this.carga_tipo_id,
          eta: null,
          etb: null,
          etc: null,
          afretador_id: null,
          destination_porto: null,
          agencia_id: null,
          porto_id: this.terminal.porto_id,
          recebedor_id: null,
          terminal_id: this.terminal.id,
          cargas_afretadores: [],
          isInEdit: true,
          is_observacao: 0,
        });
      },
      getRowStyleByClientType(row) {
        if (row.is_observacao) return;
        const style = "background-color:";
        switch (row.client_type) {
          case "HUSBANDRY":
            return style + "rgb(220 255 220)";
          case "FULL":
            return style + "#d6e9ff";
          case "PROTECTIVE AGENTS":
            return style + "#fbe5bd";
          case "SURVEYOR":
            return style + "#ffd663;";
        }
      },
      formatDate(value) {
        const date = this.$moment(value);
        if (date.year() !== this.$moment().year()) {
          return date.format("DD - MMM YYYY");
        }
        return date.format("DD - MMM");
      },
      setEditLine(row) {
        this.editRow = { ...row };
        row.isInEdit = true;
      },
      cancelEditLine(row) {
        this.$helper.set(row, this.editRow, Object.keys(row));
        // console.log(row);
        row.cargas_afretadores = row.cargas_afretadores.filter((c) => c.id);
        row.isInEdit = false;
      },
      saveRow(row) {
        this.rowloading = true;
        this.errors = {};
        Service.submit(row)
          .then((response) => {
            this.$helper.set(row, response.data, Object.keys(row));
            row.isInEdit = false;
            this.rowloading = false;
            this.$emit("refresh");
            this.$notify({
              type: "success",
              message: "Lineup saved successfully!",
              horizontalAlign: "center",
            });
          })
          .catch((err) => {
            this.rowloading = false;
            if (err.response && err.response.data && err.response.data.errors) {
              let errors = err.response.data.errors;
              this.errors = errors;
              this.$notify({
                type: "danger",
                message: "Please check the lineup",
                horizontalAlign: "center",
              });
            }
          });
      },
      getClass(row) {
        if (row.status != "Expected") return;
        const eta = new Date(row.eta).getTime();
        const now = new Date().getTime();
        return eta <= now ? "text-warning" : "";
      },
      handleExcluir(updated) {
        this.$root.modal.excluir.isOpen = false;
        if (updated) this.$parent.listar();
      },
      marcarAtualizado(lineup, atualizar) {
        this.loading = true;
        Service.atualizar(lineup, atualizar).then((response) => {
          lineup.atualizado_data = response.data.atualizado_data;
          this.$notify({
            type: "success",
            message: "Atualizado com sucesso!",
            horizontalAlign: "center",
          });
          this.loading = false;
        });
      },
      alteraData(lineup, campo) {
        Service.alteraData(lineup, campo).then((response) => {
          lineup[campo] = response.data[campo];
          lineup.espera = response.data.espera;
        });
      },
      listar() {
        this.loading = true;
        this.list = [];
        Service.get({
          ...this.search,
          with: [
            "carga",
            "cargaTipo",
            "terminal",
            "berco",
            "afretador",
            "agencia",
            "navio",
            "recebedor",
            "cargas_afretadores.recebedor",
          ],
        }).then((response) => {
          this.list = response.data;
          this.loading = false;
        });
      },
      toggleEdit(value = null) {
        this.errors = {};
        const isInEdit = value !== null ? value : !this.edit;
        if (!isInEdit) {
          this.list = this.list
            .concat(this.deletedLines)
            .map((l) => {
              l.cargas_afretadores = l.cargas_afretadores.filter((c) => c.id);
              return l;
            })
            .filter((l) => l.id);
          this.deletedLines = [];
        }
        this.list = this.list.map((l) => ({ ...l, isInEdit }));
        this.edit = isInEdit;
      },
      openModalCreate(type, model, index) {
        switch (type) {
          case "agencia":
            this.modal.create.service = AgenciasService;
            this.modal.create.params = {};
            break;

          case "navio":
            this.modal.create.service = NaviosService;
            this.modal.create.params = {};
            break;

          case "recebedor":
            this.modal.create.service = RecebedoresService;
            this.modal.create.params = {};
            break;

          case "trabalhador":
            this.modal.create.service = TrabalhadoresService;
            this.modal.create.params = {};
            this.modal.create.model = model;
            this.modal.create.index = index;
            break;

          case "afretador":
            this.modal.create.service = AfretadoresService;
            this.modal.create.params = {};

            break;
          case "lineup_porto":
            this.modal.create.service = LineupsPortosService;
            this.modal.create.params = {};
            this.modal.create.model = model;

            break;

          case "carga":
            // if (!this.carga_tipo_id) {
            //   this.$notify({
            //     type: "danger",
            //     message: "Please select the comm type",
            //     horizontalAlign: "center",
            //   });
            //   return false;
            // }
            this.modal.create.service = CargasService;
            this.modal.create.params = {};
            break;
        }

        this.modal.create.type = type;
        this.modal.create.show = true;
      },
      handleModalForm(model) {
        switch (this.modal.create.type) {
          case "agencia":
            this.list[this.rowIndex].agencia_id = model.id;
            this.agencias[this.agencias.length] = model;
            break;
          case "navio":
            this.list[this.rowIndex].navio_id = model.id;
            this.navios[this.navios.length] = model;
            break;
          case "recebedor":
            this.list[this.rowIndex].recebedor_id = model.id;
            this.recebedores[this.recebedores.length] = model;
            break;

            break;
          case "lineup_porto":
            this.list[this.rowIndex].destination_porto = model.id;
            this.lineupPortos[this.lineupPortos.length] = model;
            break;
          case "afretador":
            this.list[this.rowIndex].afretador_id = model.id;
            this.afretadores[this.afretadores.length] = model;
            break;
          case "carga":
            this.list[this.rowIndex].carga_id = model.id;
            this.cargas[this.cargas.length] = model;
            break;
        }
        this.modal.create.type = null;
        this.modal.create.show = false;
      },
      getValue(modelName, modelId, key = "name") {
        return this[modelName].find((m) => m.id == modelId)[key];
      },
      checkValue(modelName, modelId, key = "name", result = "TBI") {
        if (!modelId) return true;
        return this[modelName].find((m) => m.id == modelId)[key] === result;
      },
      setStatus(row) {
        if (row.status !== "Sailed") return;
        let haveTBI = false;
        if (this.checkValue("navios", row.navio_id)) haveTBI = true;
        if (this.checkValue("cargas", row.carga_id)) haveTBI = true;
        if (this.checkValue("afretadores", row.afretador_id)) haveTBI = true;
        if (this.checkValue("lineupPortos", row.destination_porto, "pais"))
          haveTBI = true;
        if (this.checkValue("agencias", row.agencia_id)) haveTBI = true;
        if (haveTBI) {
          row.status = null;
          return this.$notify({
            type: "danger",
            message: "Please inform all fields",
            horizontalAlign: "center",
          });
        }
        if (this.checkValue("agencias", row.agencia_id, "name", "NAABSA")) {
          if (!confirm("All fields are correct ?")) row.status = null;
        }
      },
      setCargaTipo(model) {
        console.log("carga load");
        // if (model.carga_tipo_id) return;
        model.carga_tipo_id = this.cargas.find(
          (c) => c.id == model.carga_id
        ).tipo_id;
      },
      async saveList() {
        this.errors = {};
        this.rowloading = true;
        try {
          await Service.saveList(
            this.orderList,
            this.terminal.id,
            this.deletedLines
          );
          this.$emit("refresh");
          this.toggleEdit();
          this.$notify({
            type: "success",
            message: "Lines saved successfully!",
            horizontalAlign: "center",
          });
        } catch (err) {
          this.$notify({
            type: "danger",
            message: "Please check all lines.",
            horizontalAlign: "center",
          });
          this.errors = err.response.data.errors;
        }
        this.rowloading = false;
      },
      deleteLine(row) {
        if (row.id) {
          this.deletedLines.push(row);
          // return this.$root.openExcluirModal(
          //   row,
          //   "Would you like to delete the lineup?",
          //   Service
          //   );
        }
        const index = this.list.indexOf(row);
        this.list.splice(index, 1);
      },
      addCargaAfretador(row) {
        row.cargas_afretadores.push({
          carga_id: null,
          qty: null,
          afretador_id: row.afretador_id,
        });
      },
      removeCargaAfretador(row, carga_afretador) {
        row.cargas_afretadores = row.cargas_afretadores.filter(
          (c) => c != carga_afretador
        );
      },
      formatQty(qty = 0) {
        qty = typeof qty == "number" ? qty : Number(qty);
        return qty.toLocaleString("en-US", {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        });
      },
      getStatusStyle(style) {
        switch (style) {
          case "Loading":
            return "font-style: italic;color:blue";
          case "Waiting":
            return "font-weight: lighter;";
          case "Expected":
            return "font-weight: bold;";
          case "Discharging":
            return "color:red;";
        }
      },
      sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
      },
      focusTargetEtc() {
        // this.showLines=false;
        this.sleep(0).then(() => {
          if (this.$refs.currentRowEtc) {
            this.$refs.currentRowEtc[0].querySelector("input").focus();
            this.targetRow = null;
          }
        });
      },
      userCanEdit() {
        if (this.$root.isOperator || this.$root.isBasicAdmin) {
          return this.userPortosIds.includes(this.terminal.porto_id);
        }
        return true;
      },
      getBerthName(row) {
        if (row.berco && row.berco.name != "TBI") return row.berco.name;
        return this.$root.isClient ? "" : "TBI";
      },
    },
  };
</script>
<style scoped>
  .card .table td,
  .card .table th {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
  }

  .table td,
  .table th {
    font-size: 0.7125rem;
    white-space: normal;
    padding: 0.1rem 1rem;
  }

  .hover-style {
    padding: 0;
    margin: 0;
    height: auto;
    font-size: 0.7125rem;
    border: none;
    width: 5.7125rem;
  }

  .observation-row {
    background-color: grey !important;
    color: white !important;
  }

  /*.hover-style:focus,.hover-style:hover{
  border:1px solid #cad1d7;
  padding: 0.625rem 0.75rem;
  width: 6.6125rem;


  }*/
  /*input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    }*/
  tr.no-border td {
    border: none;
  }

  tr.with-border td {
    border-top: none;
    border-bottom: solid 1px #e9ecef;
  }
  td.no-text {
    color: transparent;
  }
</style>
<style type="">
  .table-multiselect {
    font-size: 0.7rem !important;
    /*margin-bottom: 0px;*/
    white-space: nowrap;
    background: white;
    max-width: 150px;
    width: 150px;
    min-width: 150px;
  }
  .table-multiselect .multiselect__single {
    font-size: 0.7rem !important;
    /*margin-bottom: 0px;*/
    overflow: hidden;
    white-space: nowrap;
  }
  .table-multiselect .multiselect__option {
    white-space: normal;
  }
  .table-multiselect .multiselect__option {
    white-space: normal;
  }
  .table-multiselect .multiselect__select {
    padding: 1px 2px 0 1px !important;
    width: 20px;
    height: 33px;
    display: none;
    /* padding: 0; */
  }

  .table-multiselect .multiselect__tags {
    height: calc(1.5em + 1.25rem + 0px) !important;
    padding: 11px 10px 0 0px !important;
  }

  tbody.list tr td {
    padding: 0 5px;
  }

  tbody.list.inEdit tr td {
    padding: 0 0px;
  }
  .table-multiselect.multiselect {
    /*  border: 1px solid #cad1d7;*/
  }

  .padding-table {
    padding-bottom: 140px;
  }

  .input.multiplo.table-multiselect .multiselect__tags {
    margin: 1px 2px 0px 40px;
    border-radius: 100px;
  }
  /*****************************************
                  NOVOS
******************************************/
  .bvtbn {
    width: 95px !important;
  }
  .btn-novo {
    width: 95px !important;
    height: 25px !important;
    font-size: 14px !important;

    margin: 0px 5px 0px 0px !important;
  }
  .descracao_mobile {
    display: none !important;
  }
  .blc-descracao-desktop {
    display: block;
  }
  #sub {
    display: flex;
  }
  #sub .submit {
    width: 95px;
    height: 40px;
    margin-top: 10px;
    margin-right: 5px;
  }
  @media (max-width: 991px) {
    .blc-descracao-desktop {
      display: none !important;
    }
    .descracao_mobile {
      display: block !important;
    }
  }
</style>
