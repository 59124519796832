import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import ArgonDashboard from "./plugins/argon-dashboard";
import axios from "axios";
import VueAxios from "vue-axios";
import moment from "moment";
import { BootstrapVue } from "bootstrap-vue";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import { helper } from "./helper.js";
import VueDragscroll from "vue-dragscroll";

Vue.use(CKEditor);
Vue.use(Datetime);
Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.prototype.$helper = helper;
Vue.prototype.$moment = moment;
Vue.config.productionTip = false;
Vue.use(ArgonDashboard);
Vue.use(VueDragscroll);

Vue.filter("date", function (value) {
  if (value) return moment(String(value)).format("DD/MM/YYYY HH:mm");
  return "TBI";
});
Vue.filter("dmy", function (value) {
  if (value) return moment(String(value)).format("DD/MM/YYYY");
  return "TBI";
});
Vue.filter("DDMMYYYY", function (value) {
  if (value) return moment(String(value)).format("DD/MM/YYYY");
  return value;
});
Vue.filter("MMMYY", function (value) {
  if (value) return moment(String(value)).format("MMM/YY");
  return value;
});
Vue.filter("DDMMM", function (value) {
  if (value) return moment(String(value)).format("DD - MMM");
  return value;
});
Vue.filter("YEAR", function (value) {
  if (value) return moment(String(value)).format("YYYY");
  return value;
});
Vue.filter("R$", function (value) {
  value = parseFloat(value);
  if (isNaN(value)) {
    return "R$ 0,00";
  }
  return value.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    style: "currency",
    currency: "BRL",
  });
});
Vue.filter("qtd", function (value) {
  if (!value) {
    return null;
  }
  return parseFloat(value).toLocaleString("pt-BR", {
    minimumFractionDigits: 3,
    style: "decimal",
  });
});
Vue.filter("number", function (x) {
  if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return 0;
});
Vue.filter("number2", function (num) {
  if (num) {
    var digits = 1;
    var si = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "" },
      { value: 1e6, symbol: "Mi" },
      { value: 1e9, symbol: "Bi" },
      { value: 1e12, symbol: "Tri" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (
      "R$" +
      ((num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol)
    );
  }
  return "R$0";
});
Vue.filter("money", function (dimdim) {
  if (dimdim) {
    dimdim += "";
    var v = dimdim.replace(/\D/g, "");

    v = (v / 100).toFixed(2) + "";
    v = v.replace(".", ",");
    v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
    v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
    dimdim = v;
    return "R$ " + dimdim;
  }
});
Vue.filter("porcent", function (value) {
  if (value) {
    value = parseFloat(value);
    return value.toFixed(2).replace(".", ",") + "%";
  }
  return "0%";
});
Vue.filter("hour", function (value) {
  if (value) {
    return moment.utc(value * 3600 * 1000).format("HH:mm");
  }
});
Vue.filter("nl2br", function (value) {
  if (value) {
    return value.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1<br />$2");
  }
});

const app = new Vue({
  router: router,
  store: store,
  el: "#app",
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isBasicAdmin() {
      return this.$store.getters.isBasicAdmin;
    },
    isOperator() {
      return this.$store.getters.isOperator;
    },
    isClient() {
      return this.$store.getters.isClient;
    },
    isSubAgentOperator() {
      return this.$store.getters.isSubAgentOperator;
    },
  },
  data() {
    return {
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      qtd: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 3,
        masked: false,
      },
      modal: {
        excluir: {
          isOpen: false,
          model: {},
          service: () => {},
          mensagem: "",
        },
        form: {
          isOpen: false,
          model: {},
        },
      },
    };
  },
  created() {
    axios.interceptors.request.use(
      (config) => {
        this.loading = true;

        return config;
      },
      function (error) {
        app.loading = false;

        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        if (
          !error.response.data.errors &&
          error.response.status != 403 &&
          error.response.status != 422 &&
          error.response.status != 400 &&
          error.response.status != 401
        ) {
          console.log(error.response);
          app.$notify({
            type: "danger",
            message: "A system error has occurred, please reload the page",
            horizontalAlign: "center",
          });
        }

        if (error.response.status === 403) {
          app.$notify({
            type: "danger",
            message: "You don't have permission for this action.",
            horizontalAlign: "center",
          });
        }
        if (error.response.status === 401) {
          app.$notify({
            type: "danger",
            message: "You must be logged in to access this page!",
            horizontalAlign: "center",
          });
          store.dispatch("logout");
        }

        return Promise.reject(error);
      }
    );
  },
  methods: {
    openExcluirModal(model, mensagem, service) {
      this.modal.excluir.mensagem = mensagem;
      this.modal.excluir.model = model;
      this.modal.excluir.service = service;
      this.modal.excluir.isOpen = true;
    },
  },
  render: (h) => h(App),
});
store.$app = app;
